<template>
  <div>
    <h2 class="text-2xl font-bold">Settle Repayments</h2>

    <div class="mb-4">
      <div class="">
        <tabs :tabs="tabs" v-model="tab" />
      </div>
    </div>

    <component
      :is="tab"
      :searchQuery="searchQuery"
      :search-field="$refs.searchField"
    />
  </div>
</template>

<script>
export default {
  components: {
    loan: () => import("./LoanRepayment"),
    // card: () => import("./CreditCardRepayment"),
    cardPayment: () => import("./CreditCardPendingPayment")
  },
  data() {
    return {
      searchQuery: "",
      tab: "loan",
      tabs: [
        { name: "loan", title: "Loan Repayments" },
        // { name: "card", title: "Credit Card Repayments" },
        { name: "cardPayment", title: "Credit Card Pending Payment" },
      ],
      lenders: this.$options.resource([]),
    };
  },
  watch: {
    tab(tab) {
      if (this.$route.query.tab !== tab) {
        this.$router.replace({ ...this.$route, query: { tab } });
      }
    }
  },
  beforeMount() {
    this.getLenders();
  },
  mounted() {
    if (this.$route.query.tab?.match(/loan|cardPayment/)) {
      this.tab = this.$route.query.tab;
    }
  },
  methods: {
    async getLenders() {
      this.lenders.loading = true;
      await this.sendRequest('admin.lenders.all', {
        success: response => {
          this.lenders.data = response.data.lenders;
        }
      });
      this.lenders.loading = false;
    },
  }
};
</script>
